import * as React from "react";
import { logger } from "bernie-core";
import { isVariantEnabled } from "components/utility/experiment";
import { inject } from "mobx-react";
import { CustomerNotifications } from "components/shared/CustomerNotifications";
import { CustomerNotificationsWithDialogWrapper } from "src/components/shared/CustomerNotifications/CustomerNotificationsWithDialogWrapper";
import {
  StorefrontWizardRegionMesoProps,
  WizardModuleDataOptions,
  StorefrontWizardRegionMesoFlexModuleResult,
  StorefrontWizardRegionMesoTemplateComponent,
  LobNameMap,
  LobFlexComponentMap,
} from "./typings";
import {
  StorefrontWizardRegionTypesContext,
  StorefrontWizardRegionTypes,
} from "components/flexComponents/PageLevelProviders/WizardContextProvider/StorefrontWizardRegionTypesContext";
import { withLocalization } from "@shared-ui/localization-context";
import { LobQueryRouter } from "src/components/shared/StorefrontWizard/LobQueryRouter";
import {
  getMesoWizardModuleData,
  getBiasedWizardLOBTemplateComponent,
  getWizardLOBTemplateComponents,
  getNonWizardLOBTemplateComponents,
} from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import {
  TabbedWizardCard,
  WizardRegionType,
} from "src/components/shared/StorefrontWizard/TabbedWizardCard/TabbedWizardCard";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { LobFormWithTabs } from "src/components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { ReasonsToBelieveWrapper } from "src/components/shared/ReasonsToBelieve/ReasonsToBelieveWrapper";
import { Experiment as BernieExperiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { getTracker } from "src/utils/TrackingUtils";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { UitkSpacing } from "@egds/react-core/spacing";
import { RegionChildrenList } from "src/utils/RegionUtils";

@withLocalization
@logger("WizardRegionMeso")
@inject("wizardState", "analytics", "page", "flexModuleModelStore")
export class StorefrontWizardRegionMeso extends React.Component<StorefrontWizardRegionMesoProps> {
  public render() {
    const { templateComponent, flexModuleModelStore, context, wizardState, analytics, blossomComponent } = this.props;

    if (!blossomComponent) {
      return null;
    }
    /* istanbul ignore if */
    if (
      !templateComponent ||
      !flexModuleModelStore ||
      !templateComponent.children ||
      templateComponent.children.length < 1
    ) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as StorefrontWizardRegionMesoFlexModuleResult | null;

    //default to false if device information is not available
    wizardState.globalWizardState.setIsDesktop(context.deviceInformation);

    if (!model || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const [biasedWizardModule] = getBiasedWizardLOBTemplateComponent(
      templateComponent.children as StorefrontWizardRegionMesoTemplateComponent[]
    );

    const { backgroundImage, defaultLOB } = model;

    const lobFlexComponentMap: LobFlexComponentMap = {
      hotel: "wizard-hotel-pwa-v2",
      flight: "wizard-flight-pwa",
      package: "wizard-package-pwa",
      car: "wizard-car-pwa",
      // keeping rail to avoid breaking templates that rely on it
      rail: "wizard-lx-pwa",
      activity: "wizard-lx-pwa",
      cruise: "wizard-cruise-pwa",
    };

    let backgroundImagePosition;

    if (defaultLOB && biasedWizardModule) {
      const singleWizardModel = flexModuleModelStore.getModel(
        biasedWizardModule.metadata.id
      ) as StorefrontWizardRegionMesoFlexModuleResult | null;
      if (!singleWizardModel) {
        return null;
      }
      backgroundImagePosition = singleWizardModel.backgroundImagePosition;
      wizardState.globalWizardState.config.navigation.defaultLob =
        lobFlexComponentMap[singleWizardModel?.selectedProduct as keyof LobFlexComponentMap];
      wizardState.globalWizardState.config.navigation.activeLob =
        lobFlexComponentMap[singleWizardModel?.selectedProduct as keyof LobFlexComponentMap];
    } else {
      wizardState.globalWizardState.config.navigation.defaultLob = defaultLOB;
      wizardState.globalWizardState.config.navigation.activeLob = defaultLOB;
    }

    const wizardFormChildren = getWizardLOBTemplateComponents(templateComponent.children);
    const isSingleLOB = wizardFormChildren?.length === 1;

    const otherFormChildren = getNonWizardLOBTemplateComponents(templateComponent.children);

    wizardState.globalWizardState.config.heroImageSrc =
      backgroundImage || wizardState.globalWizardState.config.heroImageSrc;

    const globalConfig = wizardState.globalWizardState.config;
    const { heroImageSrc } = globalConfig;

    const uitkFigureStyle = {
      backgroundImage: `url("${heroImageSrc}")`,
      backgroundPosition: `${backgroundImagePosition || "initial"}`,
      backgroundSize: `cover`,
    };
    if (biasedWizardModule) {
      const singleWizardModel = flexModuleModelStore.getModel(
        biasedWizardModule.metadata.id
      ) as StorefrontWizardRegionMesoFlexModuleResult | null;
      getMesoWizardModuleData(singleWizardModel as WizardModuleDataOptions, wizardState);
    }

    const isStorefrontPage = context.searchContext.pageType.indexOf("Storefront") > -1;
    const reasonsToBelieveOn = isVariantEnabled(this.props.context, "Surface_PWA_SF_Travel_Advisory_Chatbot", 2);
    const wizard = `SimpleContainer ${globalConfig.halfWizard ? "halfWizard" : ""}`;

    const heroImageFigureClassNames = ` heroImageFigure ${reasonsToBelieveOn ? "withBackgroundColor" : ""}`;

    const lobNameMap: LobNameMap = {
      "wizard-hotel-pwa-v2": "_Hotels",
      "wizard-flight-pwa": "_Flights",
      "wizard-package-pwa": "_Packages",
      "wizard-car-pwa": "_Cars",
      "wizard-lx-pwa": "_Lx",
      "wizard-cruise-pwa": "_Cruise",
    };

    const trackOnChangeTab = (tabName: string) => {
      const moduleName = templateComponent.type ?? "SFWizard";
      const tracker = getTracker(wizardState.canTrack, analytics, moduleName);
      const lobName = lobNameMap[tabName as keyof LobNameMap];
      const rfrr = `LOB_Selection_Occured${lobName || ""}`;
      tracker.trackEvent(rfrr);
    };

    /**
     *
     * Note for modules below this region:
     * Modules not part of this region do not align to the form content out of the box
     * Setting the flex manager attribute brandModulePadding to bex_padding puts a spacing__twelve on the components below this region
     * This aligns them with the form content within the card on this wizard
     * On mobile, there is no card, and this same attribute puts spacing__six on these components
     */

    /*
      This particular set of styles is to position the background image inside UitkFigure.
      This feature will hopefully be added as a standard UitkFigure property which can be configured as an enum.
    */

    return (
      <>
        <StorefrontWizardRegionTypesContext.Provider value={StorefrontWizardRegionTypes.STOREFRONT_WIZARD_REGION_BEX}>
          <UitkSpacing margin={!reasonsToBelieveOn ? { blockend: "six" } : undefined}>
            <div className="WizardRegionMeso" data-testid="storefront-region">
              <div data-testid="main-region">
                <Viewport>
                  <ViewSmall>
                    <>
                      <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
                      {isStorefrontPage && (
                        <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                      )}
                      {isSingleLOB ? (
                        <UitkSpacing padding={{ inline: "six" }}>
                          <div data-testid="single-lob">
                            <RegionChildrenList
                              templateComponents={wizardFormChildren}
                              blossomComponent={blossomComponent}
                            />
                          </div>
                        </UitkSpacing>
                      ) : (
                        <LobFormWithTabs
                          context={context}
                          templateComponents={wizardFormChildren}
                          blossomComponent={blossomComponent}
                          wizardFormSpacing={{ padding: { inline: "six" } }}
                          trackOnChangeTab={trackOnChangeTab}
                        />
                      )}
                    </>
                  </ViewSmall>

                  <ViewMedium>
                    <UitkFigure
                      className={heroImageFigureClassNames}
                      style={uitkFigureStyle}
                      ratio={UitkFigureAspectRatioType.R4_1}
                    >
                      <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
                      {isStorefrontPage && (
                        <UitkSpacing padding={{ inline: "six", blockstart: "twelve" }}>
                          <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                        </UitkSpacing>
                      )}
                      <TabbedWizardCard
                        context={context}
                        wizardFormChildren={wizardFormChildren}
                        wizardState={wizardState}
                        wrappingClassNames={wizard}
                        wrappingSpacing={{ padding: { inline: "six", blockstart: "twelve" } }}
                        tabGroupClassNames="formAlignedTabs"
                        wizardFormClassNames="wizardOverHeroImage"
                        wizardFormSpacing={{ padding: { inline: "six" } }}
                        trackOnChangeTab={trackOnChangeTab}
                        wizardRegionType={WizardRegionType.MESO}
                        blossomComponent={blossomComponent}
                      />
                    </UitkFigure>
                  </ViewMedium>
                </Viewport>
                {otherFormChildren}
              </div>
            </div>
          </UitkSpacing>
          <BernieExperiment name="Surface_PWA_SF_Travel_Advisory_Chatbot">
            <ExperimentControl />
            <ExperimentVariant bucket={1}>
              <UitkSpacing padding={{ inline: "six" }}>
                <CustomerNotificationsWithDialogWrapper
                  location="IN_PAGE"
                  className="customerNotificationSquare customerNotificationWithDialog"
                />
              </UitkSpacing>
            </ExperimentVariant>
            <ExperimentVariant bucket={2}>
              <ReasonsToBelieveWrapper location="IN_PAGE" className="reasons-to-believe" />
            </ExperimentVariant>
          </BernieExperiment>
        </StorefrontWizardRegionTypesContext.Provider>
      </>
    );
  }
}

export default StorefrontWizardRegionMeso;
